import React, { useState } from 'react';

const currentDay = new Date();
const stringDate = `${currentDay.getDate()}.${
    currentDay.getMonth() + 1
}.${currentDay.getFullYear()}`;

const Submission = ({ data }) => {
    const contactInfo = JSON.parse(data);

    const [date, setDate] = useState(stringDate);
    const [answer, setAnswer] = useState('');

    const subject = `Sendung vom ${date}`;
    const body = `Das gesuchte Wort war: ${answer}.%20%0D%0A%20%0D%0AMit freundlichen Grüßen`;
    const signature = `${contactInfo.name}%20%0D%0A%20%0D%0A${contactInfo.address}`;

    return (
        <div className="form">
            <label htmlFor="answer">
                <input
                    id="answer"
                    type="text"
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                />
                The answer
            </label>
            <label htmlFor="date">
                <input
                    id="answer"
                    type="text"
                    onChange={(e) => setDate(e.target.value)}
                    required
                    value={date}
                />
                Date
            </label>
            <a
                className="cta-button"
                href={`mailto:sonntagsraetsel@deutschlandfunkkultur.de?subject=${subject}&body=${body}%20%0D%0A%20%0D%0A${signature}`}
            >
                send
            </a>
        </div>
    );
};

export default Submission;

import React, { useState } from 'react';
import Cookies from 'js-cookie';

const Configurator = () => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');

    const handleSubmit = () => {
        const data = { name, address };

        Cookies.set('SonntagsRaetsel', JSON.stringify(data), { expires: 365 });
    };

    return (
        <form className="form" onSubmit={handleSubmit}>
            <h2>How this works</h2>
            <p>
                With this utility you can easily have your answers sent as an email from your own
                email program.
            </p>
            <p>
                Your contact data is stored locally on your browser, meaning your data never touches
                our servers.
            </p>
            <h2>Your data</h2>
            <p>Please fill in the data you want to send to Deutschlandfunk Kultur:</p>
            <label htmlFor="name">
                <input id="name" type="text" onChange={(e) => setName(e.target.value)} required />
                Name
            </label>
            <label htmlFor="address">
                <input
                    id="address"
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                Address
            </label>
            <button className="cta-button" type="submit">
                save
            </button>
        </form>
    );
};

export default Configurator;

import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Configurator from '../components/SonntagsRaetsel/Configurator';
import Submission from '../components/SonntagsRaetsel/Submission';

const SonntagsRaetselPage = () => {
    const [step, setStep] = useState(0);
    const Cookie = Cookies.get('SonntagsRaetsel');

    const evaluateCookie = useCallback(() => {
        if (Cookie) {
            setStep(1);
        }
    }, [Cookie]);

    useEffect(() => {
        setTimeout(() => {
            evaluateCookie();
        }, 100);
    }, [evaluateCookie]);

    return (
        <Layout>
            <SEO title="Sonntagsrätsel" description="A simple tool for sending in your answers." />
            <section>
                <div className="padded full-height">
                    <h1>Sonntagsrätsel</h1>
                    <p>
                        A simple tool for sending in your answers. In case you need to listen in
                        again:{' '}
                        <a
                            className="link"
                            href="https://www.deutschlandfunkkultur.de/sonntagsraetsel-100.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            go listen
                        </a>
                        .
                    </p>
                    {step === 0 && <Configurator />}
                    {step === 1 && <Submission data={Cookie} />}
                </div>
            </section>
        </Layout>
    );
};

export default SonntagsRaetselPage;
